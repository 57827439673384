<!-- A设备管理-备件管理-备件出库记录-详情 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">出库信息</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <!-- <el-form-item label="id：">
                    <el-input v-model="formInline.ID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item> -->
                <el-form-item label="工单编号：">
                    <el-input v-model="formInline.orderID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="是否领用：">
                     <el-checkbox v-model="formInline.isPick" disabled class="width_180 text_align_left"></el-checkbox>
                    <!-- <el-input v-model="formInline.isPick" placeholder="" class="width_180" disabled></el-input> -->
                </el-form-item>
                <!-- <el-form-item label="项目编号：">
                    <el-input v-model="formInline.projectID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item> -->
                <el-form-item label="当前审批顺序">
                    <el-input v-model="formInline.authSort" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="是否审批：">
                    <el-checkbox v-model="formInline.isAuth" disabled class="width_180 text_align_left"></el-checkbox>
                    <!-- <el-input v-model="formInline.isAuth" placeholder="" class="width_180" disabled></el-input> -->
                </el-form-item>
                <el-form-item label="领用工单编号：">
                    <el-input v-model="formInline.pickOrderID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="申请时间：">
                    <!-- <el-input v-model="formInline.applyTime" placeholder="" class="width_180" disabled></el-input> -->
                    <el-date-picker prefix-icon="el-icon-date" v-model="formInline.applyTime" type="datetime"
                        :clearable="false" placeholder="选择日期时间" disabled>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="申请人：">
                    <el-input v-model="formInline.applyPerson" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="仓库：">
                    <el-input v-model="formInline.warehouse" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="部门：">
                    <el-input v-model="formInline.department" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="出库用途：">
                    <el-input v-model="formInline.outwhUse" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="工单状态：">
                    <el-input v-model="formInline.orderStatus" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item label="出库总价：">
                    <el-input v-model="formInline.totalPrice" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-form :model="formInline" label-width="110px" style="width:65%; ">
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="3" v-model="formInline.remarks" resize="none" disabled></el-input>
                </el-form-item>
            </el-form>

            <!-- 第二部分 -->
            <div class="box_head1 width_100">备件信息</div>
            <!-- table -->
            <div class="height_300" style="width: 65%;">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" class="mg_top_10 "
                @selection-change="handleSelectionChange" height="100%" border style="width:100%">
                <el-table-column type="index" label="序号" width="80"></el-table-column>
                <!-- <el-table-column prop="ID" label="id" width=""></el-table-column> -->
                <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sparePartID" label="备件编号" width=""></el-table-column>
                <el-table-column prop="sparePartName" label="备件名称" width=""></el-table-column>
                <el-table-column prop="sparePartModel" label="备件型号" width=""></el-table-column>
                <el-table-column prop="unit" label="单位" width=""></el-table-column>
                <el-table-column prop="applyQuan" label="申请数量" width=""></el-table-column>
                <el-table-column prop="unitPrice" label="单价" width=""></el-table-column>
                <el-table-column prop="totalPrice" label="总价" width=""></el-table-column>
                <el-table-column prop="remarks" label="备注" width=""></el-table-column>
            </el-table>
            </div>
            <!-- <div class="flex justify_between align_center" style="width: 65%;">
                <div class="color_808080">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->

            <div class="flex mg_top_30 mg_bottom_30">
                <!-- <el-button type="primary" class="width_140" @click="sbumit">修改</el-button> -->
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        sparePartOutWarehouseQueryLimit,
        sparePartOutWarehouseMultiAuthQueryLimit,
        sparePartOutWarehouseDetailQuery,
        sparePartOutWarehouseDetailQueryLimit,
        sparePartOutWarehouseQuery
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                tableData: [],
                formInline: {},
                detailID: ''
            }
        },
        mounted() {
            // this.formInline = this.$route.query.data;
            if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.sparePartOutWarehouseQuery();
                // this.sparePartOutWarehouseDetailQuery();
            }
        },
        methods: {
            // sbumit() {
            //     this.$router.go(-1);
            // },
            async sparePartOutWarehouseQuery() {
                var param = {
                    // ID: 14,
                    ID: this.detailID
                }
                const res = await sparePartOutWarehouseQuery(param);
                this.formInline = res.rows[0];
                this.sparePartOutWarehouseDetailQuery();
            },
            async sparePartOutWarehouseDetailQuery() {
                var param = {
                    // ID: 14,
                    orderID:  this.formInline.orderID
                }
                const res = await sparePartOutWarehouseDetailQuery(param);
                this.tableData = res.rows;
                // console.log(this.tableData)
            },
            colsePage() {
                this.$router.go(-1);
            },
            // table选择
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
        }
    }
</script>
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }

    ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 0px;
    }
</style>